import React, { useEffect, useState, useRef } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { isAndroid } from '../../../utils/index'
import downloadfiles from '../../../utils/downloadfile.js'
import style from './index.module.less'
import classNames from 'classnames'
import { TouMingBaiBtn } from '../../../components/index'
import {
    phoneIcon,
    ppIcon,
    ttIcon,
    emailIcon,
    urlIcon,
    userIcon,
    closeIcon,
    supportIcon,
    playVideoIcon
} from '../../../images/arlab/index'

import { DetailCaroul, DetailPic } from "../../../components/Arlab/index"
import { navigate } from "gatsby"
import pic4 from "../../../images/image_dots.svg"
import { useIntl } from "react-intl"
import useNavigate from '@/hooks/useNavigate'
import { getAppShow } from '@/services/request'


const Index = (props) => {
    const intl = useIntl()
    const [params, setParams] = useState()
    const isPhoneAndroid = isAndroid()
    const [ifRender, setIfRender] = useState(false)
    const [ifApkCode, setIfApkCode] = useState(false)
    const [ifBigPic, setIfBigPic] = useState(false)
    const [ifBigVideo, setIfBigVideo] = useState(false)
    const [bigPicIndex, setBigPicIndex] = useState(0)

    const downloadApk = (src) => {
        if (isPhoneAndroid) {
            //安卓，打开下载链家
            downloadfiles(params.downloadUrl)
        } else {
            //其他，打开二维码
            setIfApkCode(true)
        }
    }
    const openBigPic = (index) => {
        setBigPicIndex(index)
        setIfBigPic(true)
    }
    const goBack = () => {
        useNavigate('/arlab')
    }
    const getdata = (id) => {
        getAppShow({ tag: id })
            .then(d => {
                setParams(d?.data?.data[0])
                setIfRender(true)
            })
    }
    useEffect(() => {

        if (!window.location.search) {
            useNavigate('/arlab')
        } else {
            let id = window.location.search.split('&')[0].split('=')[1]
            getdata(id)
        }

    }, [])


    return (<> {ifRender &&
        <Layout menuBlack={false}>
            <SEO page="AR_Lab" />
            <div style={{ backgroundColor: "#111" }}>
                <div className={style.apkBox} style={{ display: ifApkCode ? 'flex' : 'none' }}>
                    <div className={style.codePic}>
                        <img src={params.qrCodeUrl} />
                    </div>
                    <div className={style.codeDes}>
                        {intl.formatMessage({ id: "arlab_code", defaultMessage: "Scan the QR code to download the app" })}
                    </div>
                    <img src={closeIcon} onClick={() => setIfApkCode(false)} />
                </div>
                {ifBigPic && <div className={style.bigPicBox} >
                    <DetailCaroul bigPicIndex={bigPicIndex} picList={params.conceptImages} />
                    <img src={closeIcon} className={style.closeBtn} onClick={() => setIfBigPic(false)} />
                </div>}
                {ifBigVideo &&
                    <div className={style.bigPicBox} >
                        <div className={style.bigPicVideo}>
                            <video autoPlay width="100%" controls playsInline>
                                <source src={params.promotionalInfo.url} type="video/mp4" />
                            </video></div>

                        <img src={closeIcon} className={style.closeBtn} onClick={() => setIfBigVideo(false)} />
                    </div>
                }
                {/* promotionalInfo type */}

                <div className={style.header}>
                    <div className={style.goBack} onClick={() => { goBack() }}></div>
                    <div className={style.headerCover}></div>
                    {params.promotionalInfo.type == 'Video' ?
                        <div className={style.headerVideo} onClick={() => { setIfBigVideo(true) }}>
                            <video autoPlay loop muted width="100%" playsInline>
                                <source src={params.promotionalInfo.url} type="video/mp4" />
                            </video>
                            <div className={style.videoMask}
                                style={{ backgroundImage: `url(${pic4})` }}
                            ></div>
                        </div>
                        :
                        <div className={style.headerImg} style={{ backgroundImage: `url(${params.promotionalInfo.url})` }}></div>
                    }

                </div>
                <div className={style.detail}>
                    <div className={style.appName}>
                        {params.name}
                    </div>

                    <div className={style.icon} style={{ backgroundImage: `url(${params.icon})` }}>

                    </div>
                    <div className={style.tagBox}>
                        {params.supportDevice.indexOf("Air") > -1 && <div className={classNames(style.tag)}>Air<img src={supportIcon} /></div>}
                        {params.supportDevice.indexOf("Light") > -1 && <div className={classNames(style.tag)}>light<img src={supportIcon} /></div>}

                    </div>
                    <div className={style.downloadBtn} onClick={() => downloadApk(params.downloadUrl)}>
                        <TouMingBaiBtn>{intl.formatMessage({ id: "nebulaPage.text24", defaultMessage: "Download" })}</TouMingBaiBtn>
                    </div>
                    <DetailPic openBigPic={openBigPic} picList={params.conceptImages} />
                    <div className={style.des}>
                        <div className={style.desTitle}>
                            {intl.formatMessage({ id: "arlab_des", defaultMessage: "Description" })}
                        </div>
                        <div className={style.desText} dangerouslySetInnerHTML={{ __html: `${params.description}`, }}>

                        </div>
                    </div>
                    <div className={style.addition}>
                        <div className={style.additionTitle}>
                            {intl.formatMessage({ id: "arlab_addition", defaultMessage: "Additional Details" })}
                        </div>
                        <div className={style.additionItem}>
                            <div className={style.additionItem_key}>
                                {intl.formatMessage({ id: "arlab_des_lang", defaultMessage: "Languages" })}
                            </div>
                            <div className={style.additionItem_val}>{params.additionalDetail.lang}</div>
                        </div>
                        <div className={style.additionItem}>
                            <div className={style.additionItem_key}>
                                {intl.formatMessage({ id: "arlab_des_size", defaultMessage: "Space Required" })}
                            </div>
                            <div className={style.additionItem_val}>{params.additionalDetail.apkSize}</div>
                        </div>
                        <div className={style.additionItem}>
                            <div className={style.additionItem_key}>
                                {intl.formatMessage({ id: "arlab_des_version", defaultMessage: "Version" })}
                            </div>
                            <div className={style.additionItem_val}>{params.additionalDetail.apkVersion}</div>
                        </div>
                        <div className={style.additionItem}>
                            <div className={style.additionItem_key}>
                                {intl.formatMessage({ id: "arlab_des_date", defaultMessage: "Release Date" })}
                            </div>
                            <div className={style.additionItem_val}>{params.additionalDetail.apkReleaseDate}</div>
                        </div>
                    </div>
                    <div className={style.info}>
                        <div className={style.infoTitle}>
                            {intl.formatMessage({ id: "arlab_des_developer", defaultMessage: "Developer Info" })}
                        </div>
                        {params.developerInfo.name && <div className={style.infoItem}>
                            <img src={userIcon} />
                            <span>{params.developerInfo.name}</span>
                        </div>}
                        {params.developerInfo.email && <div className={style.infoItem}>
                            <img src={emailIcon} />
                            <span>{params.developerInfo.email}</span>
                        </div>}
                        {params.developerInfo.phoneNumber && <div className={style.infoItem}>
                            <img src={phoneIcon} />
                            <span>{params.developerInfo.phoneNumber}</span>
                        </div>}
                        {params.developerInfo.devWebsite && <div className={style.infoItem}>
                            <img src={urlIcon} />
                            <span><a target='_blank' href={params.developerInfo.devWebsite}>
                                {intl.formatMessage({ id: "arlab_des_web", defaultMessage: "Developer Website" })}
                            </a></span>
                        </div>}
                        {params.developerInfo.policy && <div className={style.infoItem}>
                            <img src={ppIcon} />
                            <span><a target='_blank' href={params.developerInfo.policy}>
                                {intl.formatMessage({ id: "footer.privacy", defaultMessage: "Privacy Policy" })}
                            </a></span>
                        </div>}
                        {params.developerInfo.termsService && <div className={style.infoItem}>
                            <img src={ttIcon} />
                            <span><a target='_blank' href={params.developerInfo.termsService}>
                                {intl.formatMessage({ id: "footer.terms", defaultMessage: "Terms of Service" })}
                            </a></span>
                        </div>}
                    </div>

                </div></div>

        </Layout>}</>


    )
}
export default Index